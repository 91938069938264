import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderInfoPage from "../components/HeaderInfoPages/HeaderInfoPages";
import FaqComponent from "../components/FaqComponent/FaqComponent"

const SeoData = {
  Title: "F.A.Q | Sajdah - The World's First Smart Educational Prayer Rug",
  Keywords: [`F.A.Q`, `Sajdah`, `Prayer`, `Rug`, `Islam`, `Smart`],
  Description: "F.A.Q for Sajdah, the world's first smart educational prayer rug that teaches new Muslims and children the second most important pillar in Islam.",
  Image: "https://getsajdah.com/sajdah_og_image_thumb.jpg",
  url: "https://getsajdah.com/faq",
  titleTemplate: "F.A.Q | Sajdah - The World's First Smart Educational Prayer Rug"
};

const FAQ = () => (
  <Layout>
    <SEO
        description={SeoData.Description}
        keywords={SeoData.Keywords}
        title={SeoData.Title}
        image={SeoData.Image}
        url={SeoData.url}
        titleTemplate={SeoData.titleTemplate}
    />
    <HeaderInfoPage title="F.A.Q" />
    <FaqComponent />
  </Layout>
);



export default FAQ;
