import React from 'react';
import styled from "styled-components";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { AnchorLink } from "gatsby-plugin-anchor-links";


const FaqComponent = () => {
    return (
        <WrapperAccordion>
            <ContainerAccordion>
                <HeaderAccordion>
                    <h3>
                        Do You Have Any Questions About Sajdah?
                    </h3>
                    <p>
                        Here we try to answer most of the frequently asked questions. If 
                        you still can not find your answer, please do not hesitate to 
                        <AnchorLink>get in touch with us.</AnchorLink>
                    </p>
                </HeaderAccordion>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                When will Sajdah be available?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                We are trying very hard to bring Sajdah to the market 
                                as soon as possible. It is still under heavy development. 
                                We are hoping to release it very soon.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How many languages will Sajdah support?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Sajdah will support Arabic and English in the beginning. 
                                Additional languages will be added later.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is the ruling on putting the Qur’aan on the prayer mat?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                There is no difference of opinion among the scholars concerning 
                                the fact that the Qur’aan must be respected and protected. 
                                As for putting the Qur’aan on clean ground, there is nothing 
                                wrong with that, because this is not disrespecting the Qur’aan 
                                or mistreating it. Besides, it is a digital screen that displays 
                                Qur’anic text, which has the same ruling as, for example, 
                                your smart phone with Qur'aan app. [islamqa.info, Sheik Abdul 
                                Aziz ibn Baz (Arabic)]
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </ContainerAccordion>
        </WrapperAccordion>
     
       
    );
}


const WrapperAccordion = styled.div`
    width:100%;
    height:auto;
`

const HeaderAccordion = styled.div`
    padding: 16px 32px;
    a {
        color: #009298;
        margin-left:4px;
        text-decoration:none;
    }
`

const ContainerAccordion = styled.div`
width:100%;
max-width: 960px;
margin:90px auto 90px;
box-shadow:0 15px 38px rgb(0 0 0 / 10%);

background-color:white;
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width:100%;
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    outline:none;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    background-color: #009298;
    color:white;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid  #444;
    border-right: 2px solid #444;
    transform: rotate(-45deg);
}
.accordion__button[aria-expanded='true'] {
    background-color: #009298;
    color:white;
}
.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
    border-bottom: 2px solid  white;
    border-right: 2px solid white;
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}
`

export default FaqComponent;